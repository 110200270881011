@if (book) {
  <div class="book" (click)="goToBook(book.uuid, book.title)"  [class.hidden]="hidden" [class.desktop]="(appService.isMobile$ | async) === false">
    <img
      [src]="book | compressedImage"
      alt="{{ book.title }}"
      class="fade-image cover"
      (load)="onLoad('image' + book.uuid + type)"
      [id]="'image' + book.uuid + type"
      [class.mobile]="appService.isMobile$ | async"/>
    <div class="book-title" [class.mobile]="appService.isMobile$ | async">
      <div class="title">
        {{ book.title }}
      </div>
    </div>
  </div>
} @else {
  <div class="book">
    <img class="cover" />
    <div class="book-title" [class.mobile]="appService.isMobile$ | async">Book title place holder to calculate the swiper height</div>
  </div>
}
