@if (appService.isMobile$ | async) {
  <div class="mobile">
    <div class="bean">
      <div class="bean-bubble">{{ beanLabel }}</div>
      <img src="assets/imgs/buddy-happy.svg" alt="" />
    </div>
    <app-button [expand]="'block'" [size]="'small'" [label]="buttonLabel" (clicked)="buttonClicked.emit()"></app-button>
  </div>
} @else {
  <div class="desktop">
    <div class="desktop-left">
      <div class="bean-bubble">
        <div class="label">{{ beanLabel }}</div>
      </div>
      <app-button [expand]="'default'" [label]="buttonLabel" (clicked)="buttonClicked.emit()"></app-button>
    </div>
    <div class="desktop-right">
      <img src="assets/imgs/buddy-happy.svg" alt="" />
    </div>
  </div>
}
